.steps-content {
    margin-top: 16px;
    border: 0px;
    border-radius: 2px;
    background-color: #FFFFFF;
    min-height: 200px;
    text-align: left;
    padding-top: 10px;
}

.ant-upload.ant-upload-select {
    float: right;
}

.react-codemirror2 {
    border: 1px solid #d9d9d9;
    margin-bottom: 18px;
}

.newtaskinputdescription {
    margin-top: 46px;
}

.cm-tab {
    background-image: url("tabimage.png");
    background-position: right;
    background-repeat: no-repeat;
}