.select-run-type-button-group {
    padding: 26px 16px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.select-run-type-button-group > button {
    min-width: 300px;
    height: 200px;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 50px;
    margin-bottom: 100px;
  }
  
.selectedButton {
    border-color: #40a9ff;
    color: #40a9ff;
}

.idleButton {
    border-color: #bfbfbf;
    color: #bfbfbf;
}

.card-header {
  font-size: 16px;
}