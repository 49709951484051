.tag {
    padding: 4px;
    text-align: center;
    position: relative;
}

.tag:first-of-type {
    border-radius: 50px 0px 0px 50px;
}
.tag:last-of-type {
     border-radius: 0px 50px 50px 0px;
}

.tag:last-of-type>.sliderbutton {
    display:none !important;
}

.tagtext {
    color: white;
    font-weight: 700;
    user-select: none;
    display: block;
    overflow: hidden;
    white-space: nowrap;
}

.sliderbutton {
    width: 2em;
    height: 1.5em;
    background-color: white;
    position: absolute;
    border-radius: 1em;
    right: calc(-1.1em);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    top: 0;
    bottom: 0;
    z-index: 10;
    cursor: ew-resize;
    user-select: none;
}
