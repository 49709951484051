@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.scrollable-small{
  overflow-y: auto;
  max-height: 40vh;
}

.scrollable-large {
  overflow-y: auto;
  max-height: 85vh;
}

.scrollable {
  overflow-y: auto;
}

.nomarkslist {
  list-style-type: none;
 }

.ant-table-filter-trigger {
  width: calc(5px + 2.5vw);
}

.reportingCols .ant-table-filter-trigger {
  width: 2.3em;
}